import React from "react";
import { useNavigate } from "react-router-dom";
import "./Slider.css";

const CatSliderNoImg = ({ Array: categories, limit }) => {
  const navigate = useNavigate();
  const lang = localStorage.getItem("language");
  const screen = localStorage.getItem("screen") || "large";

  const getName = (item) => {
    const name =
      lang === "ar" ? item.nameAR : lang === "tr" ? item.nameTR : item.name;
    return name?.length > 20 ? name.slice(0, 20) + "..." : name;
  };

  const handleButtonClick = (id) => {
    navigate(`/products/${id}/category`);
  };

  return (
    <swiper-container
      slides-per-view={screen === "large" ? limit : 4}
      speed="500"
      loop="true"
    >
      {categories?.map((item) => (
        <swiper-slide key={item._id} className="swiperSlide category-list">
          <div className="category-wrapper">
            <div
              className="Cat-info custom-nav-item"
              onClick={() => handleButtonClick(item._id)}
            >
              {getName(item)}
            </div>

            {item.children && item.children.length > 0 && (
              <ul className="child-dropdown">
                {item.children.map((child) => (
                  <li
                    key={child._id}
                    className="child-item"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleButtonClick(child._id);
                    }}
                  >
                    {getName(child)}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </swiper-slide>
      ))}
    </swiper-container>
  );
};

export default CatSliderNoImg;
