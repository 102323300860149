import { useEffect, useState } from "react";
import {
  useAddAddressItemMutation,
  useGetAddressInfoQuery,
  useRemoveAddressItemMutation,
  useUpdateAddressItemMutation,
  useGetOneAddressItemMutation,
} from "../../RTK/Api/AddressApi";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import TransHook from "./../translation/trans-hook";
import cities from "../../assets/js/citiesTownsTR.json";

const AddressesHook = (onCancel = null) => {
  const [alias, setAlias] = useState("");
  const [fullName, setFullName] = useState("");
  const [city, setCity] = useState("");
  const [details, setDetails] = useState("");
  const [town, setTown] = useState("");
  const [districts, setDistricts] = useState([]);
  const [phone, setPhone] = useState("");
  const [isCommercial, setIsCommercial] = useState(null);
  const [taxNo, setTaxNo] = useState("");
  const [taxAdministration, setTaxAdministration] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [inputErrors, setInputErrors] = useState({
    alias: false,
    fullName: false,
    city: false,
    town: false,
    details: false,
    phone: false,
    taxNo: false,
    taxAdministration: false,
    companyName: false,
  });

  const handleAliasChange = (e) => setAlias(e.target.value);
  const handleFullNameChange = (e) => setFullName(e.target.value);
  const handleDetailsChange = (e) => setDetails(e.target.value);
  const handleTownChange = (e) => setTown(e.target.value);
  const handleIsCommercialChange = (e) => setIsCommercial(e.target.checked);
  const handleTaxNoChange = (e) => setTaxNo(e.target.value);
  const handleTaxAdministrationChange = (e) =>
    setTaxAdministration(e.target.value);
  const handleCompanyNameChange = (e) => setCompanyName(e.target.value);
  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    setPhone(value);
  };
  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    setCity(selectedCity);

    // Find the selected city in the JSON data
    const selectedCityData = cities?.data?.find(
      (c) => c.il_adi === selectedCity
    );

    // Update districts based on selected city
    setDistricts(selectedCityData ? selectedCityData.ilceler : []);
  };

  const { data: addressGet, error: addressGetError } = useGetAddressInfoQuery();
  if (addressGetError) {
    toast.error(addressGetError);
    console.error(addressGetError);
  }

  const formData = {
    name: fullName,
    alias,
    details,
    phone,
    city,
    town,
    isCommercial,
    taxNo,
    taxAdministration,
    companyName,
  };

  const [addAddressItem, { error: addError }] = useAddAddressItemMutation();
  if (addError) {
    toast.error(addError);
    console.error(addError);
  }

  const { t } = TransHook();

  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const data = Cookies.get("userData");
    if (data) {
      setUserData(JSON.parse(data));
    }
    window.scrollTo(0, 0);
  }, []);

  const saveAddress = () => {
    if (userData) {
      addAddressItem(formData)
        .unwrap()
        .then(() => toast.success(t("addressAdded")))
        .catch((error) => toast.error(`${t("failedToAdd")} ${error}`));
    } else {
      localStorage.setItem("savedShippingAddress", JSON.stringify(formData));
      localStorage.setItem("savedBillingAddress", JSON.stringify(formData));
    }
    clearFields();
    if (onCancel) onCancel();
  };

  const clearFields = () => {
    setAlias("");
    setFullName("");
    setCity("");
    setDetails("");
    setTown("");
    setPhone("");
    setIsCommercial(false);
    setTaxNo("");
    setTaxAdministration("");
    setCompanyName("");
  };

  const setFormData = (data) => {
    setAlias(data.alias);
    setFullName(data.name);
    setCity(data.city);
    setDetails(data.details);
    setTown(data.town);
    setPhone(data.phone);
    setIsCommercial(data.isCommercial);
    setTaxNo(data.taxNo);
    setTaxAdministration(data.taxAdministration);
    setCompanyName(data.companyName);
  };

  const [removeAddressItem, { error: removeError }] =
    useRemoveAddressItemMutation();
  if (removeError) {
    // toast.error(removeError);
    console.error(removeError);
  }
  const deleteAddress = (id) => {
    removeAddressItem(id)
      .unwrap()
      .then(() => toast.success(t("itemRemoved")))
      .catch((error) => toast.error(`${t("failedToRemove")} ${error}`));
  };

  const [updateAddressItem, { error: updateError }] =
    useUpdateAddressItemMutation();
  if (updateError) {
    // toast.error(updateError);
    console.error(updateError);
  }
  const updateAddress = (id, data) => {
    updateAddressItem({ itemId: id, newItemData: data })
      .unwrap()
      .then(() => {
        toast.success(t("updateSuccess"));
        clearFields();
        onCancel();
      })
      .catch((error) => toast.error(`${t("failedToUpdate")} ${error}`));
  };

  const [getOneAddressItem, { error: getOneError }] =
    useGetOneAddressItemMutation();
  if (getOneError) {
    toast.error(getOneError);
    console.error(getOneError);
  }
  const getOneAddress = async (id) => {
    try {
      const data = await getOneAddressItem(id).unwrap();
      setFormData(data);
      return data;
    } catch (error) {
      toast.error(`${t("failedToFetchData")} ${error}`);
      throw error;
    }
  };

  return {
    addressGet,
    alias,
    fullName,
    city,
    details,
    town,
    phone,
    isCommercial,
    taxNo,
    taxAdministration,
    companyName,
    districts,
    inputErrors,
    setInputErrors,
    handleAliasChange,
    handleFullNameChange,
    handleCityChange,
    handleDetailsChange,
    handleTownChange,
    handlePhoneChange,
    handleIsCommercialChange,
    handleTaxNoChange,
    handleTaxAdministrationChange,
    handleCompanyNameChange,
    saveAddress,
    clearFields,
    deleteAddress,
    updateAddress,
    getOneAddress,
    setFormData,
  };
};

export default AddressesHook;
