import React, { useEffect } from "react";
import AddressesHook from "../../hooks/profile/AddressesHook";
import { toast } from "react-toastify";
import TransHook from "../../hooks/translation/trans-hook";
import cities from "../../assets/js/citiesTownsTR.json";

const Shippings = ({ active, onCancel, editAddressId }) => {
  const {
    handleAliasChange,
    handleFullNameChange,
    handleCityChange,
    handleDetailsChange,
    handleTownChange,
    handlePhoneChange,
    handleIsCommercialChange,
    handleTaxNoChange,
    handleTaxAdministrationChange,
    handleCompanyNameChange,
    setInputErrors,
    inputErrors,
    alias,
    fullName,
    city,
    details,
    town,
    phone,
    isCommercial,
    taxNo,
    taxAdministration,
    companyName,
    districts,
    saveAddress,
    updateAddress,
    getOneAddress,
  } = AddressesHook(onCancel);

  const { t } = TransHook();

  useEffect(() => {
    if (editAddressId) {
      getOneAddress(editAddressId)
        .then((data) => {
          handleAliasChange({ target: { value: data?.data?.alias } });
          handleFullNameChange({ target: { value: data?.data?.name } });
          handleCityChange({ target: { value: data?.data?.city } });
          handleDetailsChange({ target: { value: data?.data?.details } });
          handleTownChange({ target: { value: data?.data?.town } });
          handlePhoneChange({ target: { value: data?.data?.phone } });
          handleIsCommercialChange({
            target: { value: data?.data?.isCommercial },
          });
          handleTaxNoChange({ target: { value: data?.data?.taxNo } });
          handleTaxAdministrationChange({
            target: { value: data?.data?.taxAdministration },
          });
          handleCompanyNameChange({
            target: { value: data?.data?.companyName },
          });
        })
        .catch((error) => toast.error(`${t("failedToFetchData")} ${error}`));
    }
  }, [editAddressId]);

  const handleSubmit = () => {
    const newInputErrors = {
      alias: alias.trim().length <= 1,
      fullName: fullName.trim().length <= 4,
      city: city.trim() === "",
      details: details.trim().length <= 10,
      town: town.trim() === "",
      phone: phone.trim().length <= 5,
      taxNo: isCommercial && taxNo.trim().length <= 5,
      taxAdministration: isCommercial && taxAdministration.trim().length <= 3,
      companyName: isCommercial && companyName.trim().length <= 3,
    };

    const hasErrors = Object.values(newInputErrors).some((error) => error);

    if (!hasErrors) {
      if (editAddressId) {
        updateAddress(editAddressId, {
          alias,
          name: fullName,
          city,
          details,
          town,
          phone,
          isCommercial,
          taxNo,
          taxAdministration,
          companyName,
        });
      } else {
        saveAddress();
      }
    } else {
      setInputErrors(newInputErrors);
      toast.error(t("invalidData"));
    }
  };

  return (
    <div
      className={`tab-pane fade ${active ? "active show" : ""}`}
      id="shipping"
      role="tabpanel"
    >
      <div className="address account-content mt-0 pt-2">
        <h4 className="title mb-3">
          {editAddressId ? t("editAddress") : t("addAddress")}
        </h4>

        <form className="mb-2" action="#">
          <div className="row">
            <div className="form-group mb-3 col-md-6">
              <label>
                {t("addressTitleHomeWork")} <span className="required">*</span>{" "}
                <span style={{ fontSize: "12" }}>
                  ({t("atLeast")} 2 {t("characters")})
                </span>
              </label>
              <input
                type="text"
                className={`form-control ${
                  inputErrors.alias ? "border-error" : ""
                }`}
                required
                minLength={2}
                maxLength={20}
                value={alias}
                onChange={handleAliasChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  {t("fullName")} <span className="required">*</span>{" "}
                  <span style={{ fontSize: "12" }}>
                    ({t("atLeast")} 5 {t("characters")})
                  </span>
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    inputErrors.fullName ? "border-error" : ""
                  }`}
                  required
                  minLength={5}
                  maxLength={50}
                  value={fullName}
                  onChange={handleFullNameChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-6">
              <label>
                {t("townCity")} <span className="required">*</span>
              </label>
              <select
                name="orderby"
                className={`form-control ${
                  inputErrors.city ? "border-error" : ""
                }`}
                onChange={handleCityChange}
                value={city}
              >
                <option value="">{t("selectCity")}</option>
                {cities?.data?.map((city, index) => {
                  return (
                    <option key={index} value={city.il_adi}>
                      {city.il_adi}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group col-md-6">
              <label>
                {t("town")} <span className="required">*</span>
              </label>
              <select
                name="district"
                className={`form-control ${
                  inputErrors.town ? "border-error" : ""
                }`}
                onChange={handleTownChange}
              >
                <option value="">{t("selectTown")}</option>
                {districts.map((district, index) => (
                  <option key={index} value={district.ilce_adi}>
                    {district.ilce_adi}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-6">
              <label>
                {t("address")} <span className="required">*</span>{" "}
                <span style={{ fontSize: "12" }}>
                  ({t("atLeast")} 15 {t("characters")})
                </span>
              </label>
              <input
                type="text"
                className={`form-control ${
                  inputErrors.details ? "border-error" : ""
                }`}
                required
                minLength={15}
                maxLength={80}
                value={details}
                onChange={handleDetailsChange}
              />
            </div>

            <div className="form-group col-md-6 mb-3">
              <label>
                {t("phoneNumber")} <span className="required">*</span>{" "}
                <span style={{ fontSize: "12" }}>
                  ({t("atLeast")} 6 {t("characters")})
                </span>
              </label>
              <input
                type="text"
                className={`form-control ${
                  inputErrors.phone ? "border-error" : ""
                }`}
                required
                value={phone}
                onChange={handlePhoneChange}
                onInput={(e) => {
                  if (e.target.value.length > 15) {
                    e.target.value = e.target.value.slice(0, 15);
                  }
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  checked={isCommercial}
                  type="checkbox"
                  className="mx-2"
                  onChange={handleIsCommercialChange}
                />
                <label>
                  {t("isCommercial")} <span className="required">*</span>
                </label>
              </div>
            </div>
          </div>

          {isCommercial && (
            <>
              <div className="row">
                <div className="form-group col-md-6">
                  <label>
                    {t("taxNo")} <span className="required">*</span>{" "}
                    <span style={{ fontSize: "12" }}>
                      ({t("atLeast")} 5 {t("characters")})
                    </span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      isCommercial && inputErrors.taxNo ? "border-error" : ""
                    }`}
                    required
                    minLength={5}
                    maxLength={15}
                    value={taxNo}
                    onChange={handleTaxNoChange}
                  />
                </div>

                <div className="form-group col-md-6 mb-3">
                  <label>
                    {t("taxAdministration")} <span className="required">*</span>{" "}
                    <span style={{ fontSize: "12" }}>
                      ({t("atLeast")} 3 {t("characters")})
                    </span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      isCommercial && inputErrors.taxAdministration
                        ? "border-error"
                        : ""
                    }`}
                    required
                    value={taxAdministration}
                    onChange={handleTaxAdministrationChange}
                    onInput={(e) => {
                      if (e.target.value.length > 15) {
                        e.target.value = e.target.value.slice(0, 15);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label>
                    {t("companyName")} <span className="required">*</span>{" "}
                    <span style={{ fontSize: "12" }}>
                      ({t("atLeast")} 3 {t("characters")})
                    </span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      isCommercial && inputErrors.companyName
                        ? "border-error"
                        : ""
                    }`}
                    required
                    minLength={3}
                    value={companyName}
                    onChange={handleCompanyNameChange}
                  />
                </div>
              </div>
            </>
          )}

          <div className="form-footer mb-0">
            <div className="form-footer-right">
              <span className="btn btn-dark py-4 mr-2" onClick={handleSubmit}>
                {editAddressId ? t("update") : t("save")}
              </span>
              <button
                type="button"
                className="btn btn-danger py-4 ml-2"
                onClick={onCancel}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Shippings;
