import React from "react";
import Cookies from "js-cookie";
import { ProductBaseUrl } from "../../api/GlobalData";
import TransHook from "../../hooks/translation/trans-hook";
import { XCircleFill } from "react-bootstrap-icons";

const OrderCard = ({ goToOrder, item, getStatus, orderStatus }) => {
  const { t } = TransHook();
  const cur = JSON.parse(Cookies.get("CompanyCurrency"));
  // Calculate the adjusted total order price
  const adjustedTotalPrice = item?.cartItems
    ?.filter(
      (item) =>
        !["cancelled", "returned"].includes(item?.orderStatus?.toLowerCase())
    )
    .reduce((total, item) => total + item.quantity * item.taxPrice, 0);

  return (
    <div
      className="order_card d-flex px-4 mb-2"
      onClick={() => goToOrder(item)}
    >
      {/* Images */}
      <div className="d-flex w-25" style={{ alignItems: "center" }}>
        {item?.cartItems?.slice(0, 2).map((product, j) => (
          <img
            key={j}
            src={ProductBaseUrl + product?.product?.imagesArray[0]?.image}
            alt={product?.product?.name?.split(" ")[0]}
          />
        ))}
      </div>

      {/* Order number */}
      <div className="d-flex w-25" style={{ alignItems: "center" }}>
        <h5 className="m-0">{t("orderNo")}:</h5>&nbsp;
        <h4 className="m-0">
          <b>{item?.orderNumber}</b>
        </h4>
      </div>

      {/* Order status */}
      {adjustedTotalPrice === 0 && orderStatus === "ongoing" ? (
        <div className="d-flex w-25" style={{ alignItems: "center" }}>
          <XCircleFill color="#f06" size={20} />
          &nbsp;{" "}
          <h5 className="m-0">
            <b>{t("cancelledOrReturned")}</b>
          </h5>
        </div>
      ) : (
        getStatus(orderStatus)
      )}

      {/* Order date and total */}
      <div style={{ alignContent: "center" }} className="w-25">
        <h5 className="m-0">{item?.date?.substring(0, 10)}</h5>
        <h4>
          <b
            style={{ color: adjustedTotalPrice === 0 ? "#ff0000" : "#00aa00" }}
          >
            {adjustedTotalPrice === 0 && orderStatus === "ongoing"
              ? t("cancelledOrReturned")
              : adjustedTotalPrice === 0
              ? t(orderStatus)
              : cur?.currencyCode +
                (
                  (adjustedTotalPrice + item?.shippingPrice) *
                    cur?.exchangeRate || 1
                ).toFixed(2)}
          </b>
        </h4>
      </div>
    </div>
  );
};

export default OrderCard;
