import React, { useState } from "react";
import LoginImg from "../../assets/images/afaqs_2022-09_3e27442e-a2a2-4b93-913c-a2ff85046fb2_Untitled.webp";
import AuthHook from "../../hooks/Auth/AuthHook";
import { useForgotPasswordMutation } from "../../RTK/Api/AuthApi";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import TransHook from "./../../hooks/translation/trans-hook";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, SetEmail] = useState("");
  const { companyLogo } = AuthHook();
  const [ForgotPassword, { data, isSuccess, isError, error }] =
    useForgotPasswordMutation();
  if (error) toast.error(error);
  // Send Email to Verify
  const SendEmailVerification = () => {
    ForgotPassword({ email });
  };

  const { t } = TransHook();
  const lang = localStorage.getItem("language");

  return (
    <div className={`AuthContainer  ${lang === "ar" ? "arabic-text" : ""}`}>
      <div className="Auth d-flex row">
        <div className="col-6 AuthImg">
          <img src={LoginImg} alt="LoginImg" />
        </div>
        <div className="col-6 AuthForm">
          <img src={companyLogo} alt="Logo" className="Logo" />
          <div className="SignInForm">
            <h2>{t("resetPassword")}</h2>
            <p>{t("resetPassMsg")}</p>
            <input
              type="email"
              className="form-control"
              name="email"
              value={email}
              onChange={(e) => SetEmail(e.target.value)}
              placeholder={t("email")}
            />
            <div className="d-flex justify-content-around">
              <button
                style={{
                  margin: "21px 10px",
                  textTransform: "capitalize",
                  borderRadius: "5px",
                  fontSize: "14px",
                }}
                type="submit"
                className="btn btn-primary"
                onClick={() => SendEmailVerification()}
              >
                {t("resetPassword")}
              </button>
              <button
                style={{
                  margin: "21px 10px",
                  textTransform: "capitalize",
                  borderRadius: "5px",
                  fontSize: "14px",
                }}
                className="btn btn-primary"
                onClick={() => navigate("/login")}
              >
                {t("returnToLogin")}
              </button>
            </div>

            {isSuccess && (
              <div className="sucess-message">
                {data?.message || t("resetLinkSent")}
              </div>
            )}
            {isError && (
              <div className="error-message">
                {error?.data?.message || t("errorOccurred")}
              </div>
            )}
          </div>
          <div className="links">
            <h3>Smartinb@2025</h3>
            <div>
              <Link to={""}>{t("termsConditions")} | </Link>
              <Link to={""}>{t("privacyPolicy")}</Link>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default ForgotPassword;
