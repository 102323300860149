import React from "react";
import { Modal } from "react-bootstrap";
import "./Checkout.css";
import creditCard from "../../assets/images/creditcard.png";
import payAtDoor from "../../assets/images/payatdoor.png";
import CheckoutHook from "./../../hooks/cart/CheckoutHook";
import TransHook from "../../hooks/translation/trans-hook";
import Loader from "./../Loaders/Loader";
import parse from "html-react-parser";
import { toast } from "react-toastify";
import { XCircle } from "react-bootstrap-icons";
import Cookies from "js-cookie";

const Checkout = ({ goBackToAddress, cartId }) => {
  const { t } = TransHook();
  const {
    activeCart,
    isLoadingCheckout,
    cur,
    lang,
    bankInfo,
    showTerms,
    isLoading,
    selectedPaymentMethod,
    totalShippingCost,
    savings,
    acceptedTerms,
    terms,
    screen,
    additionRate,
    grandTotal,
    paymentMethods,
    paymentMethod,
    totalProductCost,
    setPaymentMethod,
    setAcceptedTerms,
    handlePaymentMethodChange,
    setShowTerms,
    createOrder,
  } = CheckoutHook(cartId);

  if (isLoadingCheckout || isLoading) {
    return <Loader />;
  } else {
    return (
      <>
        <Modal show={showTerms} onHide={() => setShowTerms(false)}>
          <Modal.Header>
            <div className="justify-content-between align-items-center d-flex w-100">
              <h2 className="m-0">{t("termsConditions")}</h2>
              <XCircle
                className="cursor-pointer"
                onClick={() => setShowTerms(false)}
                size={20}
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            {terms?.map((term, i) => {
              return (
                <p style={{ fontSize: "16px" }} key={i}>
                  {parse(term.description)}
                </p>
              );
            })}
          </Modal.Body>
        </Modal>

        <div
          className={`row ${lang === "ar" ? "arabic-text" : ""}`}
          style={{ justifyContent: "space-around" }}
        >
          {/* Order summary START */}
          <div className="col-lg-10 mx-auto">
            <div className="order-summary">
              <h3>{t("yourOrder")}</h3>

              <table className="table table-mini-cart">
                <thead>
                  <tr>
                    <td style={{ border: "none" }} colSpan="3">
                      <h4>{t("product")}</h4>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {activeCart?.cartItems?.map((item, i) => {
                    const prodName =
                      lang === "en"
                        ? item?.product?.name
                        : lang === "tr"
                        ? item?.product?.nameTR
                        : item?.product?.nameAR;
                    return (
                      <tr key={i}>
                        <td className="product-col" colSpan="2">
                          <h3 className="product-title d-flex">
                            {prodName}
                            <h4 className="product-qty">
                              &nbsp;×{item.quantity}
                            </h4>
                          </h3>
                        </td>

                        <td className="price-col">
                          <h4>
                            {cur?.currencyCode}
                            {(
                              item.taxPrice *
                                (1 + additionRate / 100) *
                                item.quantity *
                                cur?.exchangeRate || 1
                            ).toFixed(2)}
                          </h4>
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={2} className="product-col">
                      <h3 className="product-title">
                        {t("totalShippingCost")}
                      </h3>
                    </td>
                    <td className="shipping-cost-col align-content-center">
                      <h4>
                        {cur?.currencyCode}
                        {(totalShippingCost * (1 + additionRate / 100)).toFixed(
                          2
                        )}
                      </h4>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="order-total">
                    <td>
                      <h4>{t("total")}</h4>
                    </td>
                    <td colSpan={2}>
                      <b className="total-price">
                        <span>
                          {cur?.currencyCode}
                          {(
                            (totalProductCost * (cur?.exchangeRate || 1) +
                              totalShippingCost) *
                            (1 + additionRate / 100)
                          ).toFixed(2)}
                        </span>
                      </b>
                      {/* <b className="total-price">
                        <span>
                          {savings !== 0 && (
                            <div className="savings-info my-3">
                              <h4
                                style={{ color: savings > 0 ? "green" : "red" }}
                              >
                                {savings > 0
                                  ? `${t("savings")}: ${
                                      cur?.currencyCode
                                    }${Math.abs(
                                      savings * cur?.exchangeRate || 1
                                    )}`
                                  : `${t("additionalCost")}: ${
                                      cur?.currencyCode
                                    }${Math.abs(
                                      savings * cur?.exchangeRate || 1
                                    )}`}
                              </h4>
                            </div>
                          )}
                        </span>
                      </b> */}
                    </td>
                  </tr>
                </tfoot>
              </table>

              {/* Payments */}
              <div className="payment-methods mb-5">
                <h3>{t("paymentMethods")}</h3>
                {!isLoading && paymentMethods.length > 0 && (
                  <div
                    className={`info-box with-icon p-0 ${
                      screen === "large" ? "d-flex" : "d-block"
                    } justify-content-around mb-4`}
                  >
                    {paymentMethods
                      .filter((item) => {
                        const userData = Cookies.get("userData");
                        // Only allow "onlinePayment" if userData doesn't exist
                        return userData || item.name === "onlinePayment";
                      })
                      .filter((item) => item.status === true)
                      .map((item, index) => (
                        <div
                          key={index}
                          className="mx-auto payment-method-button"
                          style={{
                            border:
                              paymentMethod === item.name
                                ? "1px solid #ddd"
                                : "transparent",
                            cursor: "pointer",
                          }}
                          onClick={() => setPaymentMethod(item.name)}
                        >
                          <label className="cursor-pointer payment-method-label">
                            <input
                              type="radio"
                              name="paymentMethod"
                              value={item.name}
                              checked={paymentMethod === item.name}
                              onChange={handlePaymentMethodChange}
                              style={{ display: "none" }}
                            />
                            &nbsp;{t(item.name)}
                          </label>

                          {item.name === "onlinePayment" && (
                            <img
                              src={creditCard}
                              alt="credit card"
                              width={200}
                              className="mx-auto"
                            />
                          )}
                          {item.name === "bankTransfer" && (
                            <div className="iban-info mx-auto">
                              <h4>
                                <span>{t("bank")}</span>: {bankInfo?.bankName}
                              </h4>
                              <h4>
                                <span>{t("ibanNumber")}</span>:{" "}
                                {bankInfo?.ibanNumber}
                              </h4>
                              <h4>
                                <span>{t("name")}</span>: {bankInfo?.ibanName}
                              </h4>
                            </div>
                          )}
                          {item.name === "payAtDoor" && (
                            <img
                              src={payAtDoor}
                              alt="pay at door"
                              width={200}
                              className="mx-auto"
                            />
                          )}
                        </div>
                      ))}
                  </div>
                )}
                {/* Description */}
                {selectedPaymentMethod?.description && (
                  <div className="payment-description my-3">
                    <h3>{t("description")}</h3>
                    <h4>{selectedPaymentMethod.description}</h4>
                  </div>
                )}
              </div>

              {/* Terms */}
              <div>
                <h3
                  style={{ width: "fit-content" }}
                  className="text-primary cursor-pointer"
                  onClick={() => setShowTerms(true)}
                >
                  {t("termsConditions")}
                </h3>
                <div className="d-flex align-items-center mb-5">
                  <input
                    type="checkbox"
                    id="terms"
                    name="terms"
                    className="cursor-pointer"
                    checked={acceptedTerms}
                    onChange={(e) => setAcceptedTerms(e.target.checked)}
                  />
                  <h3
                    className="my-0 mx-2 cursor-pointer"
                    onClick={(e) => setAcceptedTerms(!acceptedTerms)}
                  >
                    {t("acceptTerms")}
                  </h3>
                </div>
              </div>

              {/* Back and Place Order buttons */}
              <div className="d-flex">
                <h4
                  className="btn btn-dark btn-place-order mx-2"
                  onClick={goBackToAddress}
                >
                  {t("backToAddress")}
                </h4>
                <h4
                  className={`btn btn-dark btn-place-order mx-2 ${
                    isLoadingCheckout && "disabled"
                  }`}
                  onClick={() => {
                    if (acceptedTerms) {
                      createOrder(paymentMethod);
                    } else {
                      setShowTerms(true);
                      toast.error(t("pleaseReadAccept"));
                    }
                  }}
                >
                  {t("placeOrder")}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Checkout;
